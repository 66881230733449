<template>
  <v-card>
    <v-card-title
      class="justify-center"
      >
      Comparador de precios
    </v-card-title>

    <v-card-text>
      <v-file-input
        truncate-length="15"
        flat
        outlined
        solo
        label="Seleccione un archivo..."
        v-model="file"
        dense
        ></v-file-input>

      <div
        class="text-right mt-3"
        >
        <v-btn
          depressed
          color="transparent"
          @click="sendFile"
          :disabled="!file || loading"
          :loading="loading"
          >
          <img
            src="@/assets/icons/tiantar-upload-excel.png"
            height="36px"
            width="36px"
            ></img>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    file: null,
    loading: false
  }),

  computed: {
    ...mapGetters(['url'])
  },

  methods: {
    sendFile () {
      const formData = new FormData();
      formData.append('file', this.file);

      const headers = { 'Content-Type': 'multipart/form-data', responseType: 'blob' };

      axios.post(`${this.url}/files`,
        formData, 
        { 
          headers,
          responseType: "blob"
        },
      ).then((response) => {
        let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = "price_comparer.xlsx",
          disposition = response.headers["content-disposition"];

        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      });
    }
  }
}
</script>
